<template>
  <div class="checkmark">
    <div class="checkmark_stem"></div>
    <div class="checkmark_kick"></div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.checkmark {
  display: inline-block;
  width: 22px;
  height: 22px;
  -ms-transform: rotate(38deg); /* IE 9 */
  -webkit-transform: rotate(38deg); /* Chrome, Safari, Opera */
  transform: rotate(38deg);
  position: relative;
  bottom: 10px;
}

.checkmark_stem {
  position: absolute;
  width: 4px;
  height: 20px;
  background-color: $red;
  left: 11px;
  top: 6px;
  border-radius: 2px 2px 0px 0px;
}

.checkmark_kick {
  position: absolute;
  width: 10px;
  height: 4px;
  background-color: $red;
  left: 4px;
  top: 22px;
  border-radius: 2px 0px 0px 2px;
}
</style>
